import {
  LigneChangementDeTaux,
  LigneDeFinancementCoutFixe,
  LigneDeblocage,
  LigneRemboursement,
} from "types";

import { uuidv4 } from "services/uuid";
import { Button } from "catalyst/button";

interface AjouterLigneDivProps {
  className?: string;
  append: (
    ligne:
      | LigneDeblocage
      | LigneDeFinancementCoutFixe
      | LigneChangementDeTaux
      | LigneRemboursement
  ) => void;
}

function dansUnAn() {
  return new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    .toISOString()
    .substring(0, 10);
}

function dansSixMois() {
  return new Date(new Date().setMonth(new Date().getMonth() + 6))
    .toISOString()
    .substring(0, 10);
}

function maintenant() {
  return new Date().toISOString().substring(0, 10);
}

export default function AjouterLigneDiv({
  append,
  className = "",
}: AjouterLigneDivProps) {
  return (
    <div className={`grid grid-cols-1 gap-4 sm:grid-cols-4 ${className}`}>
      <Button
        color="dark"
        onClick={() =>
          append({
            id: uuidv4(),
            type: "DEBLOCAGE",
            montant: 500_000,
            tauxInteret: 4,
            description: "Déblocage",
            date: maintenant(),
          })
        }
      >
        
        Déblocage
      </Button>
      <Button
        color="dark"
        onClick={() =>
          append({
            id: uuidv4(),
            type: "CHANGEMENT_DE_TAUX",
            tauxInteret: 2.5,
            montant: 0,
            description: "Changement de taux",
            date: dansSixMois(),
          })
        }
      >
        
        Changement de taux
      </Button>
      <Button
        color="dark"
        onClick={() =>
          append({
            id: uuidv4(),
            type: "COUT_FIXE",
            montant: 3000,
            tauxInteret: 0,
            description: "",
            date: maintenant(),
          })
        }
      >
        
        Coût fixe
      </Button>
      <Button
        color="dark"
        onClick={() =>
          append({
            id: uuidv4(),
            type: "REMBOURSEMENT",
            montant: -500_000,
            tauxInteret: 0,
            description: "Remboursement",
            date: dansUnAn(),
          })
        }
      >
        
        Remboursement
      </Button>
    </div>
  );
}
