import { VarianteForm } from "types";
import { Button } from "catalyst/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "catalyst/dialog";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Input } from "catalyst/input";
import { Field, Label } from "catalyst/fieldset";
import { Select } from "catalyst/select";
import InputNumber from "components/form/InputNumber";
import { TrashIcon } from "@heroicons/react/24/outline";
import AjouterLigneDiv from "./AjouterLigneDiv";

interface FinancementModalProps {
  index: number;
  isOpen: boolean;
  hide: () => void;
  onUpdate: () => void;
}

export default function FinancementModal({
  index,
  isOpen,
  hide,
  onUpdate,
}: FinancementModalProps) {
  const methods = useFormContext<VarianteForm>();
  const {
    fields: lignes,
    append,
    remove,
  } = useFieldArray({
    control: methods.control,
    name: `financements.${index}.lignes` as `financements.0.lignes`,
  });
  const watchedLignes = useWatch({
    control: methods.control,
    name: `financements.${index}.lignes` as `financements.0.lignes`,
  });
  return (
    <Dialog open={isOpen} onClose={hide} size="5xl">
      <DialogTitle>Ajouter un financement</DialogTitle>
      <DialogBody>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4 mb-5">
          <Field>
            <Label>Nom</Label>
            <Input
              {...methods.register(`financements.${index}.nom`)}
              autoFocus
            />
          </Field>
        </div>
        <div className="space-y-4">
          {lignes.map((ligne, i) => (
            <div
              key={ligne.id}
              className="grid grid-cols-1 gap-8 sm:grid-cols-6 sm:gap-4"
            >
              <Field>
                <Label>Description</Label>
                <Input
                  {...methods.register(
                    `financements.${index}.lignes.${i}.description`
                  )}
                  autoFocus
                />
              </Field>
              <Field>
                <Label>Date</Label>
                <Input
                  {...methods.register(
                    `financements.${index}.lignes.${i}.date`
                  )}
                  type="date"
                />
              </Field>
              <Field>
                <Label>Type</Label>
                <Select
                  {...methods.register(
                    `financements.${index}.lignes.${i}.type`
                  )}
                >
                  <option value="DEBLOCAGE">Déblocage</option>
                  <option value="CHANGEMENT_DE_TAUX">Changement de taux</option>
                  <option value="COUT_FIXE">Cout fixe</option>
                  <option value="REMBOURSEMENT">Remboursement</option>
                </Select>
              </Field>
              {watchedLignes?.[i]?.type !== "CHANGEMENT_DE_TAUX" && (
                <Field>
                  <Label>Montant</Label>
                  <InputNumber
                    name={`financements.${index}.lignes.${i}.montant`}
                  />
                </Field>
              )}
              {(watchedLignes?.[i]?.type === "DEBLOCAGE" ||
                watchedLignes?.[i]?.type === "CHANGEMENT_DE_TAUX") && (
                <Field>
                  <Label>Taux</Label>
                  <InputNumber
                    type="percentage"
                    name={`financements.${index}.lignes.${i}.tauxInteret`}
                  />
                </Field>
              )}
              <div className="self-end w-16">
                <button
                  onClick={() => remove(i)}
                  className="text-red-600 hover:text-red-900 mb-2"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          ))}
        </div>

        <AjouterLigneDiv className="mt-4" append={append} />
      </DialogBody>
      <DialogActions>
        <Button key="annuler" color="white" onClick={hide}>
          Annuler
        </Button>
        <Button type="submit" onClick={() => onUpdate()} color="blue">
          Modifier
        </Button>
      </DialogActions>
    </Dialog>
  );
}
