import { Fragment, MouseEventHandler } from "react";
import { ConclusionLot, IConclusion } from "types";
import {
  chaineVersMasqueEuro,
  chaineVersMasquePourcentage,
} from "services/transform";
import { ListBulletIcon, TableCellsIcon } from "@heroicons/react/20/solid";
import useLocalStorage from "hooks/useLocalStorage";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "catalyst/table";

function ToggleTableButton({
  displayVertical,
  onClick,
}: {
  displayVertical: boolean;
  onClick: MouseEventHandler<SVGSVGElement>;
}) {
  return displayVertical ? (
    <TableCellsIcon
      className="pointer text-gray-400 h-5 w-5 inline ml-2"
      onClick={onClick}
    />
  ) : (
    <ListBulletIcon
      className="pointer text-gray-400 h-5 w-5 inline ml-2"
      onClick={onClick}
    />
  );
}

function LotsConclusionDoubleTable({
  lotsConclusions,
}: {
  lotsConclusions: ConclusionLot[];
}) {
  const [displayVertical, setDisplayVertical] = useLocalStorage(
    "displayConclusionTableVertical",
    false
  );

  const lotsConclusionsReversed = lotsConclusions.reduce(
    (acc, lot) => {
      acc.designation.push(lot.designation);
      acc.TVA_deductible.push(lot.TVA_deductible);
      acc.TVA_collectee.push(lot.TVA_collectee);
      acc.prix_m2.push(lot.prix_m2);
      acc.cout_revient_fiscal.push(lot.cout_revient_fiscal);
      acc.marge_fiscale.push(lot.marge_fiscale);
      acc.marge_fiscale_TVA.push(lot.marge_fiscale_TVA);
      acc.TVA_sur_marge.push(lot.TVA_sur_marge);
      acc.TVA_lot.push(lot.TVA_lot);
      acc.cout_total_lot.push(lot.cout_total_lot);
      acc.marge_lot.push(lot.marge_lot);
      acc.pourcentage_marge_lot.push(lot.pourcentage_marge_lot);
      return acc;
    },
    {
      designation: [],
      TVA_deductible: [],
      TVA_collectee: [],
      prix_m2: [],
      cout_revient_fiscal: [],
      marge_fiscale: [],
      marge_fiscale_TVA: [],
      TVA_sur_marge: [],
      TVA_lot: [],
      cout_total_lot: [],
      marge_lot: [],
      pourcentage_marge_lot: [],
    } as {
      [K in keyof ConclusionLot]: ConclusionLot[K][];
    }
  );

  const Title = () => (
    <span className="flex flex-row gap-2 items-center">
      Lots
      <ToggleTableButton
        displayVertical={displayVertical}
        onClick={() => setDisplayVertical(!displayVertical)}
      />
    </span>
  );

  if (displayVertical) {
    return (
      <Table grid dense>
        <TableHead>
          <TableRow>
            <TableHeader
              colSpan={1 + lotsConclusionsReversed.designation.length}
              className="text-base"
            >
              <Title />
            </TableHeader>
          </TableRow>
          <TableRow>
            <TableHeader>Désignation</TableHeader>
            {lotsConclusionsReversed.designation.map((designation, i) => (
              <TableHeader key={i}>{designation}</TableHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            <Fragment>
              <TableRow>
                <TableCell>TVA Deductible</TableCell>
                {lotsConclusionsReversed.TVA_deductible.map(
                  (TVA_deductible, i) => (
                    <TableCell key={i}>
                      {chaineVersMasqueEuro(TVA_deductible)}
                    </TableCell>
                  )
                )}
              </TableRow>
              <TableRow>
                <TableCell>TVA Collectée</TableCell>
                {lotsConclusionsReversed.TVA_collectee.map(
                  (TVA_collectee, i) => (
                    <TableCell key={i}>
                      {chaineVersMasqueEuro(TVA_collectee)}
                    </TableCell>
                  )
                )}
              </TableRow>
              <TableRow>
                <TableCell>Prix m2</TableCell>
                {lotsConclusionsReversed.prix_m2.map((prix_m2, i) => (
                  <TableCell key={i}>{chaineVersMasqueEuro(prix_m2)}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>Cout revient fiscal</TableCell>
                {lotsConclusionsReversed.cout_revient_fiscal.map(
                  (cout_revient_fiscal, i) => (
                    <TableCell key={i}>
                      {chaineVersMasqueEuro(cout_revient_fiscal)}
                    </TableCell>
                  )
                )}
              </TableRow>
              <TableRow>
                <TableCell>Marge fiscale</TableCell>
                {lotsConclusionsReversed.marge_fiscale.map(
                  (marge_fiscale, i) => (
                    <TableCell key={i}>
                      {chaineVersMasqueEuro(marge_fiscale)}
                    </TableCell>
                  )
                )}
              </TableRow>
              <TableRow>
                <TableCell>Marge fiscale TVA</TableCell>
                {lotsConclusionsReversed.marge_fiscale_TVA.map(
                  (marge_fiscale_TVA, i) => (
                    <TableCell key={i}>
                      {chaineVersMasqueEuro(marge_fiscale_TVA)}
                    </TableCell>
                  )
                )}
              </TableRow>
              <TableRow>
                <TableCell>TVA sur marge</TableCell>
                {lotsConclusionsReversed.TVA_sur_marge.map(
                  (TVA_sur_marge, i) => (
                    <TableCell key={i}>
                      {chaineVersMasqueEuro(TVA_sur_marge)}
                    </TableCell>
                  )
                )}
              </TableRow>
              <TableRow>
                <TableCell>TVA lot</TableCell>
                {lotsConclusionsReversed.TVA_lot.map((TVA_lot, i) => (
                  <TableCell key={i}>{chaineVersMasqueEuro(TVA_lot)}</TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>Cout total lot</TableCell>
                {lotsConclusionsReversed.cout_total_lot.map(
                  (cout_total_lot, i) => (
                    <TableCell key={i}>
                      {chaineVersMasqueEuro(cout_total_lot)}
                    </TableCell>
                  )
                )}
              </TableRow>
              <TableRow>
                <TableCell>Marge lot</TableCell>
                {lotsConclusionsReversed.marge_lot.map((marge_lot, i) => (
                  <TableCell key={i}>
                    {chaineVersMasqueEuro(marge_lot)}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell>% marge lot</TableCell>
                {lotsConclusionsReversed.pourcentage_marge_lot.map(
                  (pourcentage_marge_lot, i) => (
                    <TableCell key={i}>
                      {chaineVersMasquePourcentage(pourcentage_marge_lot)}
                    </TableCell>
                  )
                )}
              </TableRow>
            </Fragment>
          }
        </TableBody>
      </Table>
    );
  }

  return (
    <Table grid dense>
      <TableHead>
        <TableRow>
          <TableHeader colSpan={12} className="text-base">
            <Title />
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableHead>
        <TableRow>
          <TableHeader></TableHeader>
          <TableHeader>TVA Deductible</TableHeader>
          <TableHeader>TVA Collectée</TableHeader>
          <TableHeader>Prix m2</TableHeader>
          <TableHeader>Cout revient fiscal</TableHeader>
          <TableHeader>Marge fiscale</TableHeader>
          <TableHeader>Marge fiscale TVA</TableHeader>
          <TableHeader>TVA sur marge</TableHeader>
          <TableHeader>TVA lot</TableHeader>
          <TableHeader>Cout total lot</TableHeader>
          <TableHeader>Marge lot</TableHeader>
          <TableHeader>% marge lot</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {lotsConclusions.map((lot, i) => (
          <TableRow key={i}>
            <TableCell>{lot.designation}</TableCell>
            <TableCell>{chaineVersMasqueEuro(lot.TVA_deductible)}</TableCell>
            <TableCell>{chaineVersMasqueEuro(lot.TVA_collectee)}</TableCell>
            <TableCell>{chaineVersMasqueEuro(lot.prix_m2)}</TableCell>
            <TableCell>
              {chaineVersMasqueEuro(lot.cout_revient_fiscal)}
            </TableCell>
            <TableCell>{chaineVersMasqueEuro(lot.marge_fiscale)}</TableCell>
            <TableCell>{chaineVersMasqueEuro(lot.marge_fiscale_TVA)}</TableCell>
            <TableCell>{chaineVersMasqueEuro(lot.TVA_sur_marge)}</TableCell>
            <TableCell>{chaineVersMasqueEuro(lot.TVA_lot)}</TableCell>
            <TableCell>{chaineVersMasqueEuro(lot.cout_total_lot)}</TableCell>
            <TableCell>{chaineVersMasqueEuro(lot.marge_lot)}</TableCell>
            <TableCell>
              {chaineVersMasquePourcentage(lot.pourcentage_marge_lot)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default function Resultats({ conclusion }: { conclusion: IConclusion }) {
  return (
    <div>
      <div>
        <Table grid dense>
          <TableHead>
            <TableRow>
              <TableHeader colSpan={2} className="text-base">
                Frais bancaires
              </TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Commission d'engagement</TableCell>
              <TableCell>
                {chaineVersMasqueEuro(
                  conclusion.frais_bancaires.commission_engagement
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Frais de dossier</TableCell>
              <TableCell>
                {chaineVersMasqueEuro(
                  conclusion.frais_bancaires.frais_de_dossier
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Hypothèque</TableCell>
              <TableCell>
                {chaineVersMasqueEuro(conclusion.frais_bancaires.hypotheque)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Intéret d'emprunt</TableCell>
              <TableCell>
                {chaineVersMasqueEuro(
                  conclusion.frais_bancaires.interet_emprunt
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Intéret de l'apport</TableCell>
              <TableCell>
                {chaineVersMasqueEuro(
                  conclusion.frais_bancaires.interet_apport
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Coût levée de fond</TableCell>
              <TableCell>
                {chaineVersMasqueEuro(
                  conclusion.frais_bancaires.cout_levee_de_fond
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total</TableCell>
              <TableCell>
                {chaineVersMasqueEuro(conclusion.frais_bancaires.total)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <div className="mt-10">
        <LotsConclusionDoubleTable
          lotsConclusions={Object.values(conclusion.lots)}
        />
      </div>
    </div>
  );
}
